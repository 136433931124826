import axios from "axios";

const baseUrl = 'https://restcountries.com/v2'

export function getAllCounties () {
   return  axios.get(`${baseUrl}/all`)
}

export  function getCountryDetail(countryCode){
   return axios.get(`${baseUrl}/alpha/${countryCode}`)
}

