import * as React from 'react';
import {Card, CardContent, CardMedia, Typography} from '@mui/material';

export function CountryCard(props) {
    return (
        <Card sx={{maxWidth: 345, width: 200}}>
            <CardMedia
                sx={{height: 140}}
                image={props.flagUrl}
                title={props.name}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {props.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {props.capital} || {props.population}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default CountryCard;