import Home from "./Pages/Home";
import './Pages/Home.css'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CountryDetail from "./Pages/CountryDetail";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/"} element={<Home/>}/>
            </Routes>
            <Routes>
                <Route path={"/countries/:countryCode"} element={<CountryDetail/>}/>
            </Routes>

        </BrowserRouter>
    );
}

export default App;
