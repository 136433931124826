import {useParams} from 'react-router-dom'
import {useEffect, useState} from "react";
import {getCountryDetail} from "../services";
import "./CountryDetail.css"


export default function CountryDetail(props) {
    const {countryCode} = useParams();
    const [detail, setDetail] = useState([]);

    useEffect(() => {
        getCountryDetail(countryCode).then(result => {
            // console.log(result.data)
            setDetail(result.data)
        })
    }, [countryCode]);


    return (
        <div className="country-detail-wrapper">
            <div>
                <img src={detail.flags?.png} alt={detail.name}/>
            </div>
            <div>Name: {detail.name}&emsp;({detail.nativeName})</div>
            <div>population: {detail.population}</div>
            <div>currency: {detail.currencies?.map(currency => currency.name).join(", ")}</div>
            <div>capital: {detail.capital}</div>
            <div>population: {detail.population}</div>
        </div>
    )
}
