import {useEffect, useState} from "react";
import {getAllCounties} from "../services";
import CountryCard from "../components/CountryCard";
import {Link} from "react-router-dom";
import "./Home.css"
import {TextField} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function Home() {

    const [allCountriesList, setCountriesList] = useState([]);
    const [filteredCountriesList, setFilteredCountriesList] = useState([]);

    const [region, setRegion] = useState('');
    const [countryName, setCountryName] = useState('');

    const handleRegionChange = (event) => {
        setRegion(event.target.value);
    }

    const handleCountryNameChange = (event) => {
        setCountryName(event.target.value);
    }


    useEffect(() => {
        getAllCounties().then((result) => {
            const countries = result.data;
            setCountriesList(countries);
            setFilteredCountriesList(countries);
        })
    }, [])

    useEffect(() => {
        // console.log("Region or country name changed: ", region, countryName)
        if (region === '' && countryName === "") {
            setFilteredCountriesList(allCountriesList);
        } else {
            let filteredCounties = allCountriesList;
            if (region.length) {
                //     step1: filter base on region
                filteredCounties = filteredCounties.filter(country => {
                    if (country.region === region)
                        return true;
                    return false;
                });
            }
            if (countryName.length) {
                //     step2: filter base on country
                filteredCounties = filteredCounties.filter(country => {
                    const lowercaseName = country.name.toLowerCase();
                    if (lowercaseName.includes(countryName.toLowerCase()))
                        return true;
                    return false;
                });
            }
            setFilteredCountriesList(filteredCounties)
        }
    }, [region, countryName, allCountriesList]);


    return (
        <div className="App">
            <div className="filters-wrapper">
                <TextField
                    sx={{width: 500}}
                    id="outlined-basic"
                    label="Filter by country name"
                    variant="outlined"
                    onChange={handleCountryNameChange}
                    value={countryName}

                />

                <h1> World Info</h1>

                <FormControl sx={{width: 500}}>
                    <InputLabel id="demo-simple-select-helper-label">Filter by Region</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={region}
                        label="Filter by Region"
                        onChange={handleRegionChange}
                    >
                        <MenuItem value={"Africa"}>Africa</MenuItem>
                        <MenuItem value={"Americas"}>Americas</MenuItem>
                        <MenuItem value={"Asia"}>Asia</MenuItem>
                        <MenuItem value={"Europe"}>Europe</MenuItem>
                        <MenuItem value={"Oceania"}>Oceania</MenuItem>
                    </Select>
                </FormControl>

            </div>
            <div className="country-card-wrapper">
                {filteredCountriesList.map(country => (
                        <Link
                            to={`/countries/${country.alpha3Code}`}
                            key={country.alpha3Code}
                            style={{textDecoration: "none"}}
                        >
                            <CountryCard
                                name={country.name}
                                capital={country.capital}
                                population={country.population}
                                flagUrl={country.flags.png}
                                key={country.alpha3Code}
                            />
                        </Link>
                    )
                )}

            </div>
        </div>
    );
}

export default Home;